<template>
  <div>
    
    <br /><br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper mt-2 mb-2 mt-md-0 mb-md-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="p-relative">
        <button
          type="button"
          class="btn mulai  font-bold"
          @click="buatShop"
        >
          <fa-icon icon="plus-circle" class="mr-1" /> BUAT TOKO
        </button>
      </div>
    </div>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleVerify(row.item.id, row.item.verified==0 ? 1 : 0)"
          v-if="row.item.verified==0"
        >
          <fa-icon icon="pencil-alt" class="mr-1" />Verify
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleVerify(row.item.id, row.item.verified==0 ? 1 : 0)"
          v-else
        >
          <fa-icon icon="minus-circle" class="mr-1" />Block
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleView(row.item)"
        >
          <fa-icon icon="search" class="mr-1" />View
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      isBusy: true,
      selectId: null,
      items: [], 
      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Nama Toko",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "email",
          label: "Email"
        },
        {
          key: "wa_number",
          label: "WA"
        },
        {
          key: "status",
          label: "Status"
        },
        { key: "actions", label: "Link" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      showPopup: false
    };
  },

  mounted() {
    this.totalRows = this.items.length;
    this.shopList();
  },

  methods: {
    ...mapActions({
      getShopList: types.GET_SHOP_LIST,
      verifyShop: types.VERIFY_SHOP
    }),
    buatShop() {
      this.$router.push("/shop/add");
    },
    shopList(level_id = null) {
      this.isBusy = true;
      this.getShopList()
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              name: res[i].name,
              link: res[i].link,
              wa_number: res[i].wa_number,
              email: res[i].email,
              base_link: res[i].base_link,
              domain: res[i].domain,
              verified: res[i].verified,
              status: res[i].verified == 1 ? 'Verified' : ''
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    handleView(shop) {
      window.open(shop.domain ? 'https://'+shop.domain : 'https://'+shop.base_link+"/@"+shop.link, "_blank");
    },
    handleVerify(id, status) {
      this.$bvModal
      .msgBoxConfirm(status ? "Verify shop?" : "Block shop", {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (value) {
          this.verifyShop({status:status, id:id})
          .then(response => {
            this.toastSuccess('b-toaster-top-center', status ? "Verified" : "Unverified");
            this.shopList();
          })
          .catch(error => {
            this.toastError('b-toaster-top-center', error);
          })
        }
      })
      .catch(err => {
        this.toastError('b-toaster-top-center', err);
      });
    },
    toastSuccess(toaster, message, append = false) {
      this.$bvToast.toast(message, {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 3000
      })
    },
    toastError(toaster, error, append = false ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 3000
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}

.product-popup {
  position: absolute;
  z-index: 99;
  right: 0;
  top: -88px;
  width: 316px;
  height: 70px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f08045;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  display: flex;
}

.product-popup__item {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #494949;
  flex-direction: column;
}

.product-popup__item:hover {
  background-color: #ffe1d2;
}

.product-popup-arrow {
  position: absolute;
  z-index: 999;
  right: 99px;
  top: -19px;
  width: 14px;
}

.search-wrappper {
  min-width: 160px;
}

.pub-unpub {
  min-width: 105px;
}
</style>
